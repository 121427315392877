import { FC, useState, useCallback } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { deleteWarehouseItems } from 'entities/Warehouse/api';
import { WarehouseItemType } from 'entities/Warehouse/types';
import { Loader } from 'shared/ui/Loader';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useWarehouseStore } from 'widgets/Extensions/Details/ui/Warehouse/state';
import { DeleteArticleModal } from 'widgets/Extensions/Details/ui/Warehouse/ui/Modals/RemoveModal';
import { ArticlesTable } from 'widgets/Extensions/Details/ui/Warehouse/ui/ArticlesListTable';
import { ManualModal } from 'widgets/Extensions/Details/ui/Warehouse/ui/Modals/create/ManualModal';
import { getPartners } from 'entities/Partners/api';
import { usePartnersStore } from 'widgets/Partners/state';

interface ArticlesTabProps {
	isLoading: boolean;
	isManualModalForCreationOpen?: boolean;
	setIsManualModalForCreationOpen?: (isOpen: boolean) => void;
	onPageChange: (page: number) => void;
}

export const ArticlesTab: FC<ArticlesTabProps> = ({
	isLoading,
	isManualModalForCreationOpen,
	setIsManualModalForCreationOpen,
	onPageChange,
}) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { articlesList } = useWarehouseStore();
	const { setPartnersData } = usePartnersStore();
	const [isManualModalForEditOpen, setIsManualModalForEditOpen] = useState(false);
	const [selectedArticlesIds, setSelectedArticlesIds] = useState<string[]>([]);
	const [articlesToDelete, setArticlesToDelete] = useState<string[]>([]);
	const [articleToEdit, setArticleToEdit] = useState<WarehouseItemType>();
	const [searchPartnersParam, setSearchPartnersParam] = useState<string | null>(null);

	useQuery({
		queryKey: ['get/partners', searchPartnersParam],
		queryFn: () => getPartners({ type: null, search: searchPartnersParam }),
		onSuccess: (response) => {
			setPartnersData(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { mutate: deleteWarehouseItemsMutate, isLoading: deleteIsLoading } = useMutation({
		mutationKey: ['delete/warehouse-items'],
		mutationFn: deleteWarehouseItems,
	});

	const onDeleteWarehouseItemsHandler = () => {
		deleteWarehouseItemsMutate(
			{ ids: articlesToDelete },
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({ queryKey: ['get/warehouse-items'] });
					enqueueSnackbar({
						message: t('extensions.snackbar.success_warehouse_deletion'),
						variant: 'success',
					});
					setSelectedArticlesIds([]);
					setArticlesToDelete([]);
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const tableEditHandler = useCallback(
		(articleId: string) => {
			if (articlesList?.results) {
				const selectedArticleToEdit = articlesList.results.find((item) => item._id === articleId);
				setArticleToEdit(selectedArticleToEdit);
				setIsManualModalForEditOpen(true);
			}
		},
		[articlesList?.results],
	);

	const tableDeleteHandler = useCallback(
		(articleId?: string) => {
			if (!articleId && !selectedArticlesIds.length) {
				enqueueSnackbar({
					message: t('extensions.snackbar.no_selected_warehouses'),
					variant: 'error',
				});
			} else {
				setArticlesToDelete(articleId ? [articleId] : selectedArticlesIds);
			}
		},
		[selectedArticlesIds],
	);

	const tableToggleWarehouseHandler = useCallback((warehouseId: string) => {
		setSelectedArticlesIds((prevState) =>
			prevState.includes(warehouseId)
				? prevState.filter((i) => i !== warehouseId)
				: [...prevState, warehouseId],
		);
	}, []);

	const tableToggleAllWarehouseHandler = useCallback(() => {
		if (selectedArticlesIds?.length) {
			setSelectedArticlesIds([]);
		} else {
			setSelectedArticlesIds(articlesList?.results.map((article) => article._id) || []);
		}
	}, [articlesList?.results, selectedArticlesIds]);

	const onManualModalCloseHandler = () => {
		setIsManualModalForEditOpen(false);
		setArticleToEdit(undefined);
		if (setIsManualModalForCreationOpen) {
			setIsManualModalForCreationOpen(false);
		}
	};

	return (
		<>
			<DeleteArticleModal
				open={!!articlesToDelete.length}
				handleClose={() => setArticlesToDelete([])}
				handleRemove={onDeleteWarehouseItemsHandler}
			/>
			<ManualModal
				articleToEdit={articleToEdit}
				open={isManualModalForCreationOpen || isManualModalForEditOpen}
				handleClose={onManualModalCloseHandler}
				setSearchPartnersParam={setSearchPartnersParam}
			/>
			{isLoading || deleteIsLoading ? (
				<Loader type="mid" />
			) : (
				<ArticlesTable
					data={articlesList}
					selectedArticlesIds={selectedArticlesIds}
					editHandler={tableEditHandler}
					deleteHandler={tableDeleteHandler}
					toggleWarehouse={tableToggleWarehouseHandler}
					toggleAllWarehouses={tableToggleAllWarehouseHandler}
					onPageChange={onPageChange}
				/>
			)}
		</>
	);
};
