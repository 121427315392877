import {
	Paper,
	Table as MUITable,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableProps,
	TableRow,
	TableBody as MUITableBody,
	Pagination,
	Box,
} from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Column } from 'shared/ui/tables/_common/types';
import { TableBody } from 'shared/ui/tables/_common/ui/TableBody';
import { TableHeader } from 'shared/ui/tables/_common/ui/TableHeader';

interface Props<T, U> extends TableProps {
	columns: Array<Column<T>>;
	rows: Array<T & { id: string; createdAt?: string; childRows?: Array<U> }>;
	childColumns?: Array<Column<U>>;
	pagination?: {
		page: number;
		totalCount: number;
		perPage: number;
		onPageChange: (page: number) => void;
	};
	isChildTable?: boolean;
}
export function TableWithHeader<T, U>({
	columns,
	rows,
	pagination,
	childColumns,
	isChildTable,
}: Props<T, U>) {
	const firstRow = rows[0];

	return (
		<>
			<TableContainer>
				<MUITable>
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									padding: '17px 0px',
									borderBottom: isChildTable
										? `3px solid ${ColorPalette.periwinkle}`
										: 'none',
								}}
								colSpan={columns.length}
							>
								<TableHeader
									columns={columns}
									additionalData={firstRow}
									childColumns={childColumns}
								/>
							</TableCell>
						</TableRow>
					</TableHead>
					<MUITableBody>
						<TableRow>
							<TableCell sx={{ padding: 0, borderBottom: 'none' }} colSpan={columns.length}>
								<Paper
									sx={{
										padding: '0',
										boxShadow: 'none',
										backgroundColor: isChildTable ? 'transparent' : ColorPalette.white,
										borderRadius: '20px',
										overflow: 'hidden',
									}}
								>
									<Table>
										<TableBody
											columns={columns}
											rows={rows}
											childColumns={childColumns}
										/>
									</Table>
								</Paper>
							</TableCell>
						</TableRow>
					</MUITableBody>
				</MUITable>
			</TableContainer>
			{pagination && (
				<Box sx={{ display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
					<Pagination
						defaultPage={pagination.page}
						sx={{
							'& .MuiPaginationItem-root': {
								'color': ColorPalette.astronaut,
								'&.Mui-selected': {
									color: ColorPalette.white,
								},
							},
						}}
						color="primary"
						size="small"
						onChange={(e, value) => {
							pagination.onPageChange(value);
						}}
						count={Math.ceil(pagination.totalCount / pagination.perPage)}
					/>
				</Box>
			)}
		</>
	);
}
