import { Table as MUITable, TableCell, TableHead, TableProps, TableRow, styled } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { prepareCell } from 'shared/ui/tables/_common/helpers/prepareCell';
import { Column } from 'shared/ui/tables/_common/types';
interface Props<T, U> extends TableProps {
	columns: Array<Column<T>>;
	additionalData?: T;
	childColumns?: Array<Column<U>>;
}
export function TableHeader<T, U>({ columns, additionalData, childColumns }: Props<T, U>) {
	return (
		<MUITable>
			<TableHead>
				<TableRow>
					{childColumns?.length && <TableCellStyled sx={{ width: '100px' }}></TableCellStyled>}
					{columns.map((column) => {
						let prepareFieldFn, renderCellFn, restHeaderCellSettings;
						if (column?.headerCellSettings) {
							const { prepareField, renderCell, ...rest } = column.headerCellSettings;
							prepareFieldFn = prepareField;
							renderCellFn = renderCell;
							restHeaderCellSettings = rest;
						}

						const preparedCell = prepareCell(
							{ ...column, ...(additionalData ?? {}) },
							prepareFieldFn,
							renderCellFn,
						);

						return preparedCell !== null ? (
							<TableCellStyled
								key={column.field}
								{...column?.commonCellSettings}
								{...restHeaderCellSettings}
							>
								<div style={{ display: 'flex', alignItems: 'center' }}>{preparedCell}</div>
							</TableCellStyled>
						) : null;
					})}
				</TableRow>
			</TableHead>
		</MUITable>
	);
}
const TableCellStyled = styled(TableCell)(() => ({
	'height': 'fit-content',
	'padding': '0 10px',
	'borderBottom': 'none',
	'border': 'none',
	'color': ColorPalette.baliHai,
	'& .MuiTableCell-head': {
		borderBottom: 'none',
		border: 'none',
	},
	'&:first-of-type': {
		paddingLeft: '24px',
	},
	'&:last-of-type': {
		paddingRight: '24px',
	},
}));
