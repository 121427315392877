import { Box } from '@mui/material';
import { WarehouseItemType, WarehouseItemDetailsType } from 'entities/Warehouse/types';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Checkbox } from 'shared/ui/Checkbox';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Column } from 'shared/ui/tables/_common/types';

export type ArticlesColumnsType = WarehouseItemType & {
	id: string;
	totalItemsCount: number;
	selectedArticlesIds: string[];
	editHandler: (articleId: string) => void;
	deleteHandler: (articleId?: string) => void;
	toggleRowCheckbox: (articleId: string) => void;
	toggleAllCheckboxes: () => void;
};

export const ArticlesColumns: Array<Column<ArticlesColumnsType>> = [
	{
		field: 'select',
		commonCellSettings: {
			style: {
				width: '65px',
				minWidth: '50px',
			},
		},
		headerCellSettings: {
			renderCell: (warehouse) => (
				<Checkbox
					checked={warehouse?.totalItemsCount === warehouse?.selectedArticlesIds.length}
					onChange={() => warehouse?.toggleAllCheckboxes()}
				/>
			),
		},
		bodyCellSettings: {
			renderCell: (article) => (
				<Checkbox
					checked={article?.selectedArticlesIds.includes(article?._id)}
					onChange={() => article?.toggleRowCheckbox(article?._id)}
				/>
			),
		},
	},
	{
		field: 'icon',
		commonCellSettings: {
			style: {
				width: '65px',
				minWidth: '50px',
			},
		},
		headerCellSettings: {
			renderCell: (article) => (
				<OptionsDropdown
					iconId={'more_horizontal_small'}
					optionList={[
						{
							label: 'shared_texts.table_dropdown.remove',
							value: 'Remove all partner',
							action: () => {
								article?.deleteHandler();
							},
						},
					]}
					forTableHeader
				/>
			),
		},
	},
	{
		field: 'article_code',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="article_code" />,
		},
		bodyCellSettings: {
			renderCell: (article) => article?.code || '-',
		},
	},
	{
		field: 'article_description',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="article_description" />,
		},
		bodyCellSettings: {
			renderCell: (article) => article?.description || '-',
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (article) => (
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<OptionsDropdown
						iconId={'more_horizontal'}
						optionList={[
							// {
							// 	label: 'shared_texts.table_dropdown.edit',
							// 	value: 'Edit partner',
							// 	action: () => {
							// 		article?.editHandler(article._id);
							// 	},
							// },
							{
								label: 'shared_texts.table_dropdown.remove',
								value: 'Remove partner',
								action: () => {
									article?.deleteHandler(article._id);
								},
							},
						]}
					/>
				</Box>
			),
		},
	},
];

export type ArticlesChildColumnsType = WarehouseItemDetailsType;

export const ArticlesChildColumns: Array<Column<WarehouseItemDetailsType>> = [
	{
		field: 'code_from_document',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="code_from_document" />,
		},
		bodyCellSettings: {
			renderCell: (article) => article?.codeFromDocument || '-',
		},
	},
	{
		field: 'description_from_document',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="description_from_document" />,
		},
		bodyCellSettings: {
			renderCell: (article) => article?.descriptionFromDocument || '-',
		},
	},
	{
		field: 'partner_name',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="partner_name" />,
		},
		bodyCellSettings: {
			renderCell: (article) => article?.partner?.name || '-',
		},
	},
	{
		field: 'partner_type',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="partner_type" />,
		},
		bodyCellSettings: {
			renderCell: (article) =>
				article?.partner ? <TableTranslationLabel path={`partners.${article.partner?.type}`} /> : '-',
		},
	},
	{
		field: 'partner_vat',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="partner_vat" />,
		},
		bodyCellSettings: {
			renderCell: (article) => article?.partner?.vatNumber || '-',
		},
	},
	{
		field: 'partner_code',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="partner_code" />,
		},
		bodyCellSettings: {
			renderCell: (article) => article?.partner?.erpCode || '-',
		},
	},
];
