import { FC } from 'react';
import { useField } from 'formik';
import { AutocompleteProps, AutocompleteOption } from 'shared/ui/autocomplete/Autocomplete';
import Autocomplete from '../Autocomplete';

const FormAutocomplete: FC<AutocompleteProps> = ({ onChange, ...props }) => {
	const [field, meta, helpers] = useField({ name: props.name });

	return (
		<Autocomplete
			{...field}
			{...props}
			onChange={(elem) => {
				onChange && onChange(elem as AutocompleteOption | AutocompleteOption[] | null);
				helpers.setValue(elem);
			}}
			errorText={meta.touched && meta.error ? (meta.error as string) : undefined}
		/>
	);
};

export default FormAutocomplete;
