import { useState } from 'react';
import { TableBody as MUITableBody, TableCell, TableProps, TableRow, Collapse, styled } from '@mui/material';
import { IconButton } from 'shared/ui/IconButton';
import { ColorPalette } from 'shared/consts/colorPalette';
import { prepareCell } from 'shared/ui/tables/_common/helpers/prepareCell';
import { Column } from 'shared/ui/tables/_common/types';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';

interface Props<T, U> extends TableProps {
	columns: Array<Column<T>>;
	rows: Array<T & { id: string; onRowChange?: () => void; childRows?: Array<U> }>;
	childColumns?: Array<Column<U>>;
}
export function TableBody<T, U>({ columns, rows, childColumns }: Props<T, U>) {
	return (
		<MUITableBody
			sx={{
				'& > * + *': {
					borderTop: `1.5px solid ${ColorPalette.zircon}`,
				},
			}}
		>
			{rows.map((row) => (
				<Row key={row.id} columns={columns} row={row} childColumns={childColumns} />
			))}
		</MUITableBody>
	);
}

interface RowProps<T, U> extends TableProps {
	columns: Array<Column<T>>;
	row: T & { id: string; onRowChange?: () => void; childRows?: Array<U> };
	childColumns?: Array<Column<U>>;
}

function Row<T, U>({ columns, row, childColumns }: RowProps<T, U>) {
	const [open, setOpen] = useState(false);
	return (
		<>
			<TableRow
				sx={{
					'position': 'relative',
					'margin': '0',
					'&:hover': { backgroundColor: ColorPalette.zircon },
					'cursor': 'pointer',
				}}
				onClick={() => {
					row.onRowChange && row.onRowChange();
				}}
			>
				{childColumns?.length && (
					<TableCellStyled sx={{ width: '100px' }}>
						{row.childRows?.length ? (
							<IconButton
								iconId={open ? 'custom_arrow_up' : 'custom_arrow_down'}
								onClick={() => setOpen(!open)}
								sxWrapper={{ boxShadow: 'none' }}
							/>
						) : null}
					</TableCellStyled>
				)}
				{columns.map((column) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore

					let prepareFieldFn, renderCellFn, restBodyCellSettings;
					if (column?.bodyCellSettings) {
						const { prepareField, renderCell, ...rest } = column.bodyCellSettings;
						prepareFieldFn = prepareField;
						renderCellFn = renderCell;
						restBodyCellSettings = rest;
					}

					const preparedCell = prepareCell(row, prepareFieldFn, renderCellFn);
					return preparedCell !== null ? (
						<TableCellStyled
							key={column.field}
							{...column?.commonCellSettings}
							{...restBodyCellSettings}
						>
							{preparedCell}
						</TableCellStyled>
					) : null;
				})}
			</TableRow>
			{/* CHILD TABLE */}
			<TableRow>
				<TableCell
					style={{ padding: 0, border: 'none', backgroundColor: 'rgb(242 249 255)' }}
					colSpan={12}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						{childColumns && (
							<TableWithHeader
								columns={childColumns}
								rows={
									row.childRows
										? (row.childRows as Array<U & { id: string; createdAt?: string }>)
										: []
								}
								isChildTable
							/>
						)}
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

const TableCellStyled = styled(TableCell)`
	padding: 0 10px;
	border: none;
	height: 81px;
	color: ${ColorPalette.astronaut};
	overflow: hidden;
	text-overflow: ellipsis;
	&:first-of-type {
		padding-left: 24px;
	}
	&:last-of-type {
		padding-right: 24px;
	}
`;
