import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetWarehouseItemsRes } from 'entities/Warehouse/api/types';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import {
	ArticlesColumns,
	ArticlesChildColumns,
} from 'widgets/Extensions/Details/ui/Warehouse/ui/ArticlesListTable/Columns';

type ArticlesTablePropsType = {
	data: GetWarehouseItemsRes | undefined;
	selectedArticlesIds: string[];
	editHandler: (articleId: string) => void;
	deleteHandler: (articleId?: string) => void;
	toggleWarehouse: (articleId: string) => void;
	toggleAllWarehouses: () => void;
	onPageChange: (page: number) => void;
};

export const ArticlesTable: FC<ArticlesTablePropsType> = ({
	data,
	selectedArticlesIds,
	editHandler,
	deleteHandler,
	toggleWarehouse,
	toggleAllWarehouses,
	onPageChange,
}) => {
	const { t } = useTranslation();
	const preparedArticles = useMemo(() => {
		if (!data?.results.length) return [];
		return data.results.map((item) => ({
			...item,
			id: item._id,
			totalItemsCount: data.totalCount,
			selectedArticlesIds,
			editHandler,
			deleteHandler,
			toggleRowCheckbox: toggleWarehouse,
			toggleAllCheckboxes: toggleAllWarehouses,
			childRows: item.itemDetails,
		}));
	}, [data, selectedArticlesIds]);

	return (
		<>
			{preparedArticles.length ? (
				<TableWithHeader
					columns={ArticlesColumns}
					rows={preparedArticles}
					childColumns={ArticlesChildColumns}
					pagination={{
						page: data?.page || 0,
						totalCount: data?.totalCount || 0,
						perPage: data?.perPage || 0,
						onPageChange,
					}}
				/>
			) : (
				<EmptyTable text={t('shared_texts.not_found')} />
			)}
		</>
	);
};
